import React, {useEffect} from "react"
import Try from "../components/try/try"
import HeaderSmall from "../components/header-small/header-small"
import SEO from "../components/seo"

const TestPage = () => {
  let searchParams = null;
  let projectName = null

  useEffect(() => {
    searchParams = new URLSearchParams(window.location.search);
    projectName = searchParams.get('projectName');
  }, [])
  console.log('projectName', projectName) 
  return (
  <>
    <SEO title="Search Tensorflow JS source code" />
    <Try isTestPage={true} projectName={projectName} />
    <div style={{ textAlign: "center", marginTop: 40 }}>
      <p>
        Powered by <a href="https://codecue.com">CodeCue.com</a>
      </p>
    </div>
  </>
)}

export default TestPage
